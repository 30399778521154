import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import * as Icon from 'react-feather';
import { Link } from "gatsby"; 




const BlogDetails = () => (
    <Layout>
        <SEO title="Mentions légales" /> 

        <Navbar />

        <PageBanner pageTitle="Mentions légales" /> 

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> Dernière mise à jour le 20 février 2023
                                        </li>
                                    </ul>
                                </div>

                                <h2>Site internet</h2> 
                                <ul>
                                    <li>Nom: fenuahosting.com</li>
                                    <li>Propriétaire: Lucas TRONCY</li>
                                    <li>Hébergeur: fenuahosting.com</li>
                                    <li>Adresse: BP62010, FAAA, 98702, Tahiti, Polynésie française</li>
                                    <li>Numéro T.A.H.I.T.I: E65514</li>
                                    <li>Les prix sont affichés hors taxe (TVA non facturée)</li>
                                </ul>

                                <h2>Accès au site</h2>
                                <p>L’accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l’envoi de courriers électroniques non sollicités.</p>

                                <h2>Gestion du site</h2>
                                <p>Pour la bonne gestion du site, l’éditeur pourra à tout moment :</p>
                                <ul>
                                    <li>suspendre, interrompre ou limiter l’accès à tout ou partie du site, réserver l’accès au site, ou à certaines parties du site, à une catégorie déterminée d’internautes ;</li>
                                    <li>supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales ;</li>
                                    <li>suspendre le site afin de procéder à des mises à jour.</li>
                                    <li>modifier les mentions légales et les conditions d’utilisations. L’utilisateur est invité à les lire régulièrement.</li>
                                </ul>

                                <h2>Responsabilités</h2>
                                <p>La responsabilité de l’éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l’accès au site ou à une de ses fonctionnalités.

                                    Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d’attaques virales par Internet. Vous êtes par ailleurs seul responsable des sites et données que vous consultez.</p>

                                    <p>L’éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :</p>

                                <ul>
                                    <li>du fait de l’usage du site ou de tout service accessible via Internet ;</li>
                                    <li>du fait du non-respect par vous des présentes conditions générales énumérées ci-dessous.</li>
                                </ul>

                                <p>L’éditeur n’est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.

Si l’éditeur venait à faire l’objet d’une procédure amiable ou judiciaire en raison de votre utilisation du site, il pourra se retourner contre vous pour obtenir l’indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer />
    </Layout>
)

export default BlogDetails;